import { PDFDocument } from 'pdf-lib';
import lomavisCreator from '../../../redux/slices/lomavisCreator/lomavisCreator';
import {
  POST_TYPE_INFORMATION_NEWS,
  POST_TYPE_KEY,
  POST_TYPE_OFFER,
  POST_TYPE_PRODUCT,
  POST_TYPE_GALLERY_IMAGE,
  POST_TYPE_TEMPLATE
} from '../config/platformFeatures';
import smartcrop from 'smartcrop';
import heic2any from 'heic2any';
import Compressor from 'compressorjs';
import { RRule } from 'rrule';
import moment from 'moment-timezone';
import {
  IMAGE_QUALITY,
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH
} from '../config/platformFeatures';
import { ALL } from '../../../config/constants';
import Pica from 'pica';
import { fileTypeFromBuffer } from 'file-type';
import piexif from 'piexifjs';
import { getBase64Strings } from 'exif-rotate-js';
import { fitBox } from 'fit-box';
import {
  generateFirebaseUploadUrl,
  isMediaImage
} from '../../../redux/slices/lomavisCreator/utils';
import axios from 'axios';
import axiosClient from '../../../utils/axios';
import toast from 'react-hot-toast';
import { store } from '../../../redux/store';

interface Dimensions {
  width: number;
  height: number;
}

export const getImageDimensions = (url: string): Promise<Dimensions> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = url;
  });
};
export const getImageDimensionRatio = async (url: string): Promise<number> => {
  const img = new Image();
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img.width / img.height);
    img.onerror = reject;
    img.src = url;
  });
};
export function getImageDimensionsFromfile(
  file: File
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = (err) => reject(err);
      img.src = event.target?.result as string; // Set the image source to the file data
    };

    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file); // Read file as data URL
  });
}

export const getVideoDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.onloadedmetadata = () => {
      resolve({ width: video.videoWidth, height: video.videoHeight });
    };

    video.onerror = (error) => {
      reject(error);
    };

    const objectURL = URL.createObjectURL(file);
    video.src = objectURL;
  });
};

export const getPdfDimensions = async (
  file: File
): Promise<{ width: number; height: number }> => {
  const arrayBuffer = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const page = pdfDoc.getPage(0);
  const { width, height } = page.getSize();
  return {
    width: Math.round(width),
    height: Math.round(height)
  };
};

export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now()
  });
};
export const blobToPNGFile = (blob, fileName) => {
  return new File([blob], fileName, {
    type: 'image/png',
    lastModified: Date.now()
  });
};

export async function urlToFileTypeData(url, filename, mimeType) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
}
export function createMockFile({
  original_filename,
  file_type,
  file_size,
  original_asset,
  video_audio_channels,
  pdf_page_count
}) {
  const fileName = `${original_filename}`;
  const mimeType = file_type;

  // Simulate the File object
  const file = {
    name: fileName,
    type: mimeType,
    size: file_size,
    url: original_asset,
    video_audio_channels: video_audio_channels,
    pdf_page_count: pdf_page_count,
    lastModified: new Date().getTime(),
    // Add any other properties or methods as needed
    slice: () => new Blob([original_asset], { type: mimeType }) // You can mock slice to return an empty Blob or similar
  };

  return file;
}

export const getPostTypesList = (lomavisCreator: any) => {
  const postTypesList = [POST_TYPE_KEY];
  const { cloudUuid, locationUuid, lomavisCreatorConfig } = lomavisCreator;

  // Add cloud specific post types
  if (cloudUuid) {
    postTypesList.push(
      ...[
        POST_TYPE_PRODUCT,
        POST_TYPE_OFFER,
        POST_TYPE_INFORMATION_NEWS,
        POST_TYPE_TEMPLATE
      ]
    );
  }

  // Add Mulitpost post types
  if (lomavisCreatorConfig?.is_multipost) {
    postTypesList.push(...[POST_TYPE_TEMPLATE]);
  }
  return postTypesList;
};

export function removeObjectsByKeys(object, keysToRemove) {
  return Object.keys(object).reduce((result, key) => {
    if (!keysToRemove.includes(key)) {
      result[key] = object[key];
    }
    return result;
  }, {});
}

export const getSmartCrop = async (
  imageUrl,
  aspectRatioWidth,
  aspectRatioHeight
) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous'; // Handle CORS issues
  img.src = imageUrl;

  return new Promise((resolve, reject) => {
    img.onload = async () => {
      try {
        const { width, height } = img;

        if (!aspectRatioWidth || !aspectRatioHeight) {
          resolve({
            x: 0,
            y: 0,
            width: width,
            height: height
          });
        }

        const cropOptions = {
          width: aspectRatioWidth,
          height: aspectRatioHeight
        };

        const result = await smartcrop.crop(img, cropOptions);
        const crop = result.topCrop;

        resolve({
          x: crop.x,
          y: crop.y,
          width: crop.width,
          height: crop.height
        });
      } catch (error) {
        reject(error);
      }
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

// export const getSmartCrop = async (imageUrl, aspectRatio) => {
//   const img = new Image();
//   img.crossOrigin = "Anonymous"; // Handle CORS issues
//   img.src = imageUrl;

//   return new Promise((resolve, reject) => {
//     img.onload = async () => {
//       try {
//         const { width, height } = img;
//         const cropWidth = Math.min(width, height * aspectRatio);
//         const cropHeight = Math.min(height, width / aspectRatio);

//         const cropOptions = {
//           width: cropWidth,
//           height: cropHeight,
//         };

//         const result = await smartcrop.crop(img, cropOptions);
//         const crop = result.topCrop;

//         resolve({
//           x: crop.x,
//           y: crop.y,
//           width: crop.width,
//           height: crop.height,
//         });
//       } catch (error) {
//         reject(error);
//       }
//     };

//     img.onerror = (error) => {
//       reject(error);
//     };
//   });
// };

// Convert ArrayBuffer to a binary string

// Function to read EXIF data from a file
export const getRealFileType = async (
  file: File
): Promise<{ ext: string; mime: string } | null> => {
  const arrayBuffer = await file.arrayBuffer(); // Convert File to ArrayBuffer
  const buffer = new Uint8Array(arrayBuffer); // Convert ArrayBuffer to Buffer (Uint8Array)

  const fileType = await fileTypeFromBuffer(buffer); // Identify the file type

  if (fileType) {
    return fileType; // { ext: 'jpg', mime: 'image/jpeg' } or null if not recognized
  } else {
    console.warn('Unable to determine the file type.');
    return null;
  }
};

export const convertHeicToJpeg = async (heicFile) => {
  try {
    const jpegBlob = await heic2any({
      blob: heicFile,
      toType: 'image/jpeg',
      quality: 1 // you can set the quality of the output JPEG file
    });

    // Check if the result is an array and use the first element if it is
    const jpegBlobSingle = Array.isArray(jpegBlob) ? jpegBlob[0] : jpegBlob;

    // Create a new File object from the JPEG Blob
    const jpegFile = new File(
      [jpegBlobSingle],
      heicFile.name.replace(/\.[^/.]+$/, '.jpg'),
      {
        type: 'image/jpeg',
        lastModified: Date.now()
      }
    );

    return jpegFile;
  } catch (error) {
    if (error?.code == 1) {
      const fileInfo = await getRealFileType(heicFile);

      if (fileInfo.mime === 'image/heic') {
        console.error('Conversion failed:', error);
        throw error;
      }

      const oldNameWithoutExtension = heicFile.name.replace(/\.[^/.]+$/, ''); // Remove the old extension
      const newFileName = `${oldNameWithoutExtension}.${fileInfo.ext}`; // Append the new extension

      // Create a new File object with updated type and name
      const newFile = new File([heicFile], newFileName, {
        type: fileInfo?.mime, // Update MIME type
        lastModified: heicFile.lastModified
      });

      return newFile;
    }
    console.error('Conversion failed:', error);
    throw error;
  }
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Helper function to convert Base64 to Blob
export const base64ToBlob = (base64: string, type: string): Blob => {
  const byteString = atob(base64.split(',')[1]); // Decode Base64 string
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([uint8Array], { type });
};

export const compressImage = async (
  file: File,
  maxWidth = 1920,
  maxHeight = 1920
) => {
  const pica = Pica({
    features: ['wasm', 'ww']
  });

  try {
    // Use createImageBitmap for efficient image decoding
    const imageBitmap = await createImageBitmap(file);

    const { newWidth, newHeight } = getNewDimensions(
      imageBitmap.width,
      imageBitmap.height,
      maxWidth,
      maxHeight
    );

    // Create OffscreenCanvas for source
    const picaCanvas = new OffscreenCanvas(
      imageBitmap.width,
      imageBitmap.height
    );
    const picaCtx = picaCanvas.getContext(
      '2d'
    ) as unknown as CanvasRenderingContext2D | null;
    if (!picaCtx) {
      throw new Error('Failed to get 2D rendering context for Pica canvas.');
    }
    picaCtx.drawImage(imageBitmap, 0, 0);

    // Create OffscreenCanvas for resizing
    const resizingCanvas = new OffscreenCanvas(newWidth, newHeight);

    // Resize using Pica
    await pica.resize(picaCanvas, resizingCanvas, { quality: 3 });

    // Convert the resized canvas to Blob
    // @ts-ignore
    const blob = await resizingCanvas.convertToBlob({ type: file.type });

    return new File([blob], file.name, {
      type: file.type,
      lastModified: Date.now()
    });
  } catch (error) {
    console.error('Error during image compression:', error);
    return file;
    throw error;
  }
};

// Helper to calculate new dimensions
const getNewDimensions = (
  originalWidth: number,
  originalHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.min(maxWidth / originalWidth, maxHeight / originalHeight);
  return {
    newWidth: Math.round(originalWidth * ratio),
    newHeight: Math.round(originalHeight * ratio)
  };
};

export async function scaleDownImage(
  file: File,
  maxWidth: number = 1920,
  maxHeight: number = 1920
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      let { width, height } = img;

      // Calculate the scaling factor while maintaining aspect ratio
      const aspectRatio = width / height;
      if (width > maxWidth || height > maxHeight) {
        if (aspectRatio > 1) {
          // Landscape image
          width = maxWidth;
          height = maxWidth / aspectRatio;
        } else {
          // Portrait image
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }
      }

      // Create a canvas and draw the resized image
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas back to a blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Could not scale down the image.'));
            }
          },
          file.type, // Preserve the original file type (e.g., 'image/jpeg')
          1 // Set image quality if applicable (e.g., JPEG compression)
        );
      } else {
        reject(new Error('Canvas context not available.'));
      }
    };

    img.onerror = () => {
      reject(new Error('Failed to load the image.'));
    };
  });
}

export function decimalToRatio(decimal: number): {
  width: number;
  height: number;
} {
  const gcd = (a: number, b: number): number => {
    if (!b) return a;
    return gcd(b, a % b);
  };

  const tolerance = 1.0e-6;
  let h1 = 1,
    h2 = 0,
    k1 = 0,
    k2 = 1,
    b = decimal;

  do {
    const a = Math.floor(b);
    const aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    const aux2 = k1;
    k1 = a * k1 + k2;
    k2 = aux2;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  const gcdValue = gcd(h1, k1);

  return { width: h1 / gcdValue, height: k1 / gcdValue };
}

// Function to get platforms with content available for preview
export const getPlatformsWithPreview = (lomavisCreatorState) => {
  // Extract platform keys from postData and filter based on platformEnabled flag
  return Object.keys(lomavisCreatorState.postData)
    .filter((platform) => lomavisCreatorState?.platformEnabled[platform])
    .filter((platform) => {
      // Exclude the "ALL" platform
      if (platform === ALL) return false;
      // Include platforms that have either media or text content
      return (
        lomavisCreatorState.postData[platform]?.media?.filter(
          (file) => file?.uploaded
        ).length > 0 ||
        lomavisCreatorState.postData[platform]?.text_length_striped > 0
      );
    });
};

// Function to check if there are any platforms with content available for preview
export const showPreview = (lomavisCreatorState) => {
  // Returns true if at least one platform has content, false otherwise
  return getPlatformsWithPreview(lomavisCreatorState).length > 0;
};

export const getObjectAsFile = (item) => {
  const mockFileData = {
    original_filename: item?.name,
    file_type: item?.file_type,
    file_size: item?.file_size,
    original_asset: item?.original_asset,
    video_audio_channels: item?.video_audio_channels,
    pdf_page_count: item?.pdf_page_count
  };

  const mockFile = createMockFile(mockFileData);
  return mockFile;
};

export const updatePlatformStatus = (platforms, platformKey, state) => {
  // Return a new object with the updated boolean value for the specified platformKey
  return {
    ...platforms, // Spread the original platforms
    [platformKey]: state // Update the specified platform's boolean value
  };
};

export const determineImageDimensionsFromURL = (
  previewImageURL: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };

    img.onerror = (error) => {
      reject(error);
    };

    // Directly set the src to the preview image URL
    img.src = previewImageURL;
  });
};

export function parseString(value) {
  if (value === 'null') return null;
  if (/^".*"$/.test(value)) return value.slice(1, -1);
  return value;
}

export function updateRecurrenceTime({ time, recurrence_as_str, timezone }) {
  const [hours, minutes] = time.split(':').map(Number);

  // Parse the existing recurrence rule
  const rule = RRule.fromString(recurrence_as_str);

  // Get the current DTSTART and update its time in the given timezone
  let dtStart = moment(rule.options.dtstart).tz(timezone).set({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0
  });

  // Update UNTIL if it exists
  let until = rule.options.until
    ? moment(rule.options.until).tz(timezone).set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0
      })
    : null;

  // Convert back to native JS Date for RRule
  const updatedRule = new RRule({
    ...rule.origOptions,
    dtstart: dtStart.toDate(),
    until: until ? until.toDate() : undefined
  });

  return updatedRule.toString();
}

export function extractTimeFromRecurrence(recurrence_as_str) {
  // Parse the rule from the recurrence string
  const rule = RRule.fromString(recurrence_as_str);

  // Get the DTSTART time
  const dtStart = rule.options.dtstart;

  if (!dtStart) {
    throw new Error('DTSTART is missing from the recurrence string.');
  }

  // Format the time as HH:mm
  const hours = dtStart.getUTCHours().toString().padStart(2, '0');
  const minutes = dtStart.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export const hexToNormalizedArray = (hex) => {
  // Remove the hash symbol if present
  hex = hex.replace(/^#/, '');

  // Parse the hex values into RGB components
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Return normalized values rounded to 4 decimal places
  return [
    parseFloat(r.toFixed(4)),
    parseFloat(g.toFixed(4)),
    parseFloat(b.toFixed(4))
  ];
};

export const normalizedArrayToHex = (color) => {
  if (!Array.isArray(color) || color.length < 3) {
    throw new Error(
      'Invalid color format. Expected an array with at least 3 elements.'
    );
  }

  // Extract RGB values (ignore alpha if provided)
  const [r, g, b] = color;

  // Convert normalized values (0–1) to hex (0–255)
  const toHex = (value) => {
    const hex = Math.round(value * 255)
      .toString(16)
      .padStart(2, '0');
    return hex.toUpperCase(); // Optional: Convert to uppercase
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const parsePercentageToThreeDecimalPlaces = (percentage) => {
  // Remove the '%' symbol and parse as a float
  const numericValue = parseFloat(percentage.replace('%', ''));
  // Round to three decimal places
  return parseFloat(numericValue.toFixed(3));
};
export function calculateMinDimensions(textInfo) {
  const { text, fontFamily, fontSize, fontStyle, fontWeight, lineHeight } =
    textInfo;

  // Create a canvas to measure the text dimensions
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set the font properties
  ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;

  // Measure text width
  const lines = text.split('\n'); // Split text by new lines
  const maxWidth = Math.max(
    ...lines.map((line) => ctx.measureText(line).width)
  );

  const parsedLineHeight =
    typeof lineHeight === 'string' && lineHeight.includes('%')
      ? parseFloat(lineHeight) / 100 // Convert "120%" to 1.2
      : parseFloat(lineHeight); // Use directly if it's a number or numeric string

  // Calculate total height
  const totalHeight = lines.length * fontSize * parsedLineHeight;

  return {
    minWidth: Math.ceil(maxWidth),
    minHeight: Math.ceil(totalHeight)
  };
}

export const importFont = (fontUrl: string): void => {
  if (!document.querySelector(`link[href="${fontUrl}"]`)) {
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = fontUrl;
    document.head.appendChild(linkElement);
    console.log(`Font imported: ${fontUrl}`);
  } else {
    console.log(`Font already imported: ${fontUrl}`);
  }
};

export function calculateFontSize(
  text: string,
  fontFamily: string,
  fontStyle: string,
  fontWeight: string,
  lineHeight: number,
  initialFontSize: number,
  maxWidth: number,
  maxHeight: number,
  textAlign: string
): number {
  const div = document.createElement('div');
  document.body.appendChild(div);

  // Set up the hidden measurement div
  div.style.position = 'absolute';
  div.style.visibility = 'hidden';
  div.style.whiteSpace = 'pre-wrap'; // Supports line breaks (\n)
  div.style.overflow = 'hidden'; // Prevent extra content from affecting dimensions
  div.style.fontFamily = fontFamily;
  div.style.fontStyle = fontStyle;
  div.style.fontWeight = fontWeight;
  div.style.lineHeight = lineHeight.toString();
  div.style.textAlign = textAlign; // Handle text alignment

  let fontSize = initialFontSize;
  div.style.fontSize = `${fontSize}px`;
  div.textContent = text; // Set the text content with \n preserved

  // Check if it fits within maxWidth and maxHeight
  while (
    (div.offsetWidth > maxWidth || div.offsetHeight > maxHeight) &&
    fontSize > 0
  ) {
    fontSize--; // Decrease font size
    div.style.fontSize = `${fontSize}px`;
  }

  document.body.removeChild(div); // Clean up
  return fontSize;
}

export function rgbStringToHex(rgbString) {
  // Extract the numeric RGB values from the string
  const [r, g, b] = rgbString
    .match(/\d+/g) // Match all numeric values
    .map(Number); // Convert them to numbers

  // Convert each value to a two-digit hexadecimal string
  const toHex = (value) => value.toString(16).padStart(2, '0');

  // Combine into a single hex string with a leading #
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const waitForGoogleFonts = async function (
  url: string
): Promise<boolean> {
  // Type definition for font face data
  interface FontFaceInfo {
    family: string;
    weight: number;
    style: string;
  }

  // Fetch the Google Fonts CSS file and extract font faces
  const getAvailableFontFaces = async function (
    url: string
  ): Promise<FontFaceInfo[]> {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(`Failed to fetch font CSS: ${response.statusText}`);

      const cssText = await response.text();
      const fontFaces: FontFaceInfo[] = [];

      // Extract @font-face rules using regex
      const fontFaceRegex = /@font-face\s*{[^}]*}/g;
      const matches = cssText.match(fontFaceRegex);

      if (!matches) return [];

      matches.forEach((rule) => {
        const familyMatch = rule.match(/font-family:\s*['"]?([^;'"]+)['"]?;/i);
        const weightMatch = rule.match(/font-weight:\s*(\d{3})/i);
        const styleMatch = rule.match(/font-style:\s*(\w+)/i);

        if (familyMatch && weightMatch && styleMatch) {
          fontFaces.push({
            family: familyMatch[1],
            weight: parseInt(weightMatch[1], 10),
            style: styleMatch[1]
          });
        }
      });

      return fontFaces;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // Ensure all fonts are loaded
  async function areAllFontsLoaded(
    fontFaces: FontFaceInfo[]
  ): Promise<boolean> {
    if (!fontFaces.length) {
      console.warn('No font faces found in the CSS.');
      return false;
    }

    if (!('fonts' in document)) {
      console.warn('document.fonts API is not supported.');
      return false;
    }

    await (document.fonts as FontFaceSet).ready;

    // Wait for all font loading promises
    const fontLoadPromises: Promise<FontFace[]>[] = fontFaces.map(
      ({ family, weight, style }) =>
        (document.fonts as FontFaceSet).load(
          `${style} ${weight} 12px '${family}'`
        )
    );

    await Promise.all(fontLoadPromises);

    // Verify all fonts are fully loaded
    return fontFaces.every(({ family, weight, style }) =>
      (document.fonts as FontFaceSet).check(
        `${style} ${weight} 12px '${family}'`
      )
    );
  }

  // Fetch and check fonts
  const fontFaces = await getAvailableFontFaces(url);
  return await areAllFontsLoaded(fontFaces);
};

export function isPastDate(dateString) {
  const inputDate = new Date(dateString);
  const today = new Date();

  // Set time to midnight (00:00:00) for an accurate date-only comparison
  today.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);

  return inputDate < today; // Returns true only if it's strictly in the past
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true; // Check for direct equality

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false; // If either is not an object, return false
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false; // Different number of keys

  return keys1.every(
    (key) => keys2.includes(key) && deepEqual(obj1[key], obj2[key]) // Recursively compare values
  );
};

export function wrapText(
  text: string,
  containerWidth: number,
  fontSize: number,
  fontFamily: string
): string {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontSize}px ${fontFamily}`;

  // Split by both spaces and newlines while preserving newlines
  const words = text.split(/(\s+|\n)/);
  let formattedText = '';
  let currentLine = '';

  words.forEach((word) => {
    if (word === '\n') {
      // Preserve explicit newlines
      formattedText += `${currentLine}\n`;
      currentLine = '';
      return;
    }

    let testLine = currentLine ? `${currentLine}${word}` : word;
    let testWidth = context.measureText(testLine).width;

    if (testWidth > containerWidth) {
      if (currentLine) {
        formattedText += `${currentLine}\n`;
      }

      currentLine = word.trim(); // Start a new line

      // If a single word is too long, break it apart
      while (
        context.measureText(currentLine).width > containerWidth &&
        currentLine.length > 1
      ) {
        let fittingPart = '';
        let remainingPart = '';

        for (let i = 0; i < currentLine.length; i++) {
          let testPart = fittingPart + currentLine[i];
          if (context.measureText(testPart).width <= containerWidth) {
            fittingPart = testPart;
          } else {
            remainingPart = currentLine.slice(i);
            break;
          }
        }

        formattedText += `${fittingPart}\n`;
        currentLine = remainingPart;
      }
    } else {
      currentLine = testLine;
    }
  });

  if (currentLine.trim()) {
    formattedText += (formattedText ? '\n' : '') + currentLine;
  }

  return formattedText.replace(/\n{2,}/g, '\n'); // Remove extra newlines
}

export function getFirstWrappedLine(
  text: string,
  containerWidth: number,
  fontSize: number,
  fontFamily: string
): string {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontSize}px ${fontFamily}`;

  const words = text.split(/(\s+|\n)/);
  let currentLine = '';

  for (const word of words) {
    if (word === '\n') {
      return currentLine.trim(); // Return the first full line on explicit newline
    }

    let testLine = currentLine ? `${currentLine}${word}` : word;
    let testWidth = context.measureText(testLine).width;

    if (testWidth > containerWidth) {
      return currentLine.trim() || word.trim(); // Return first full line
    } else {
      currentLine = testLine;
    }
  }

  return currentLine.trim(); // If text fits within one line, return as is
}

export function getTextWidth(
  text: string,
  fontSize: number,
  fontFamily: string,
  fontWeight: string = 'normal'
): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return 0;

  context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  return context.measureText(text).width;
}

export const handleUploadMedia = async ({
  sourceFile,
  dimensions,
  location_uuid,
  uploadType,
  t
}) => {
  console.log('sourceFile', sourceFile);
  if (isMediaImage(sourceFile)) {
    const file = sourceFile;

    console.log('File', file);

    const file_type = file.type;
    const fileSizeInBytes = file.size;

    let uploadPreset = await generateFirebaseUploadUrl(
      {
        content_type: file_type,
        filesize_in_bytes: fileSizeInBytes,
        filename: file.name,
        location_uuid: location_uuid,
        width: dimensions?.width,
        height: dimensions?.height,
        upload_type: uploadType
      },
      {},
      () => console.log('error here')
    );

    const url = uploadPreset?.url;
    const headers = uploadPreset?.headers;

    try {
      const config = {
        headers: headers ? { ...headers, 'Content-Type': file_type } : {} // Set headers property with provided headers
      };

      const response = await axios.put(url, file, {
        ...config
      });

      console.log('return data', response);

      try {
        await axiosClient.put(uploadPreset?.confirm_url, {
          success: true
        });

        return uploadPreset;
      } catch (err: any) {
        console.log(err);
        throw err;
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        toast.error(`${t('errors.request_couldnt_be_completed')}`, {
          duration: 4000,
          position: 'top-right'
        });
        const res = error.response;

        console.error(`Looks like there was a problem. Status Code: ${error}`);

        console.error('Error:', error);
        throw error;
      }
      toast.error(` ${t('errors.request_couldnt_be_completed')}`, {
        duration: 4000,
        position: 'top-right'
      });

      // Dispatch deleteAfile action inside the catch block
    }
  }
};

export const sleepFor1000Ms = async () => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, 3000);
  });
};

export const waitForUUIDs = async (uuids: string[]) => {
  return new Promise<void>((resolve) => {
    const checkState = () => {
      const state = store.getState(); // Access the current Redux state
      const allSet = uuids.every((uuid) =>
        Object.values(state.lomavisCreator.postData).some((platform: any) =>
          platform.media.some((obj) => obj.uuid === uuid)
        )
      );

      if (allSet) {
        resolve(); // All UUIDs are set
      } else {
        setTimeout(checkState, 100); // Retry after 100ms
      }
    };
    checkState();
  });
};

export function compareAspectRatios(
  svgFile: File,
  imageSource: Blob | string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    function getSvgAspectRatio(file: File): Promise<number> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          if (!event.target?.result) return reject('Failed to read SVG file.');

          const parser = new DOMParser();
          const doc = parser.parseFromString(
            event.target.result as string,
            'image/svg+xml'
          );
          const svg = doc.querySelector('svg');

          if (svg) {
            let width = parseFloat(svg.getAttribute('width') || '0');
            let height = parseFloat(svg.getAttribute('height') || '0');

            // If width/height are not set, fallback to viewBox
            if (!width || !height) {
              const viewBox = svg.getAttribute('viewBox')?.split(' ');
              if (viewBox && viewBox.length === 4) {
                width = parseFloat(viewBox[2]);
                height = parseFloat(viewBox[3]);
              }
            }

            if (width && height) return resolve(width / height);
          }

          reject(
            'SVG does not have valid width, height, or viewBox attributes.'
          );
        };

        reader.onerror = () => reject('Error reading SVG file.');
        reader.readAsText(file);
      });
    }

    function getImageAspectRatio(source: Blob | string): Promise<number> {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve(img.width / img.height);
        img.onerror = () => reject('Failed to load image.');

        if (source instanceof Blob) {
          img.src = URL.createObjectURL(source);
        } else {
          img.src = source; // Assume valid URL
        }
      });
    }

    Promise.all([getSvgAspectRatio(svgFile), getImageAspectRatio(imageSource)])
      .then(([svgAspectRatio, imageAspectRatio]) => {
        console.log('aspect ratios', svgAspectRatio, imageAspectRatio);
        const isMatching = Math.abs(svgAspectRatio - imageAspectRatio) < 0.01; // Small tolerance
        resolve(isMatching);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(false);
      });
  });
}

export function getSvgAspectRatio(svgFile: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (!event.target?.result) return reject('Failed to read SVG file.');

      const parser = new DOMParser();
      const doc = parser.parseFromString(
        event.target.result as string,
        'image/svg+xml'
      );
      const svg = doc.querySelector('svg');

      if (svg) {
        let width = parseFloat(svg.getAttribute('width') || '0');
        let height = parseFloat(svg.getAttribute('height') || '0');

        // If width/height are not set, fallback to viewBox
        if (!width || !height) {
          const viewBox = svg.getAttribute('viewBox')?.split(' ');
          if (viewBox && viewBox.length === 4) {
            width = parseFloat(viewBox[2]);
            height = parseFloat(viewBox[3]);
          }
        }

        if (width && height) return resolve(width / height);
      }

      reject('SVG does not have valid width, height, or viewBox attributes.');
    };

    reader.onerror = () => reject('Error reading SVG file.');
    reader.readAsText(svgFile);
  });
}
export async function getSvgAspectRatioFromUrl(
  svgUrl: string
): Promise<number> {
  try {
    const response = await fetch(svgUrl);
    if (!response.ok) throw new Error('Failed to fetch SVG file.');

    const svgText = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgText, 'image/svg+xml');
    const svg = doc.querySelector('svg');

    if (svg) {
      let width = parseFloat(svg.getAttribute('width') || '0');
      let height = parseFloat(svg.getAttribute('height') || '0');

      // If width/height are not set, fallback to viewBox
      if (!width || !height) {
        const viewBox = svg.getAttribute('viewBox')?.split(' ');
        if (viewBox && viewBox.length === 4) {
          width = parseFloat(viewBox[2]);
          height = parseFloat(viewBox[3]);
        }
      }

      if (width && height) return width / height;
    }

    throw new Error(
      'SVG does not have valid width, height, or viewBox attributes.'
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// export function getTextMetrics(font, text) {
//   // Create an off-screen canvas to avoid dependency on a DOM element
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');

//   // Set the font
//   ctx.font = font;

//   // Measure the text
//   const metrics = ctx.measureText(text);

//   return metrics;
// }

export function getTextMetrics({
  size,
  family,
  weight = 'normal',
  style = 'normal',
  variant = 'normal',
  text
}) {
  // Create an off-screen canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Construct the font string dynamically
  const fontString = `${style} ${variant} ${weight} ${size}px ${family}`;
  ctx.font = fontString;

  // Measure the text
  const metrics = ctx.measureText(text);

  // Return bounding box properties
  return {
    actualBoundingBoxAscent: metrics.actualBoundingBoxAscent,
    actualBoundingBoxDescent: metrics.actualBoundingBoxDescent,
    actualBoundingBoxLeft: metrics.actualBoundingBoxLeft,
    actualBoundingBoxRight: metrics.actualBoundingBoxRight,
    width: metrics.width,
    fontBoundingBoxAscent: metrics.fontBoundingBoxAscent || null,
    fontBoundingBoxDescent: metrics.fontBoundingBoxDescent || null,
    hangingBaseline: metrics.hangingBaseline || null,
    ideographicBaseline: metrics.ideographicBaseline || null
  };
}
