import React from 'react';
import { AdditionalOptionsFirstComment } from './includes/FirstComment';
import { TIKTOK_BUSINESS_PAGE } from '../../../../config/constants';

export const TikTokAdditionalOptions: React.FC<any> = ({
  platform = TIKTOK_BUSINESS_PAGE
}) => {
  return (
    <div className="border border-secondary px-2 py-2 rounded">
      {/* Render only the first comment field */}
      <AdditionalOptionsFirstComment platform={platform} />
    </div>
  );
};
